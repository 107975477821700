import React from "react";
import Loader from "../../images/instaswapLoaderIcon.png";

const DataLoader2 = () => {
  const wrapperStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:  '#313535',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 9999,
  };

  return (
    <div  style={wrapperStyle}>
      <img
        src={Loader}
        alt="instaswap logo loader"
        style={{ width: "400px", height: 'auto' }}
      />
    </div>
  );
};

export default DataLoader2;