import { combineReducers } from "redux";
import userReducer from "./user.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    userReducer,
    ...asyncReducers,
  });

export default createReducer;
