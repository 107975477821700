import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import {
  apiRequestLimits,
  getAvailableChains,
  getAvailableCurrencies,
} from "../store/actions";
import DataLoader from "../components/globalView/DataLoader";
import Select, { components } from "react-select";

const AvailableCoinPairsPage = () => {
  const dispatch = useDispatch();

  const availableChains = useSelector(
    (state) => state.userReducer.availableChains
  );
  const availableCurrencies = useSelector(
    (state) => state.userReducer.availableCurrencies
  );

  const [selectedChain, setSelectedChain] = useState(null);
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [chainOptions, setChainOptions] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getAvailableChains());
    dispatch(getAvailableCurrencies());
    dispatch(apiRequestLimits());
  }, [dispatch]);

  const filterCurrencies = useCallback(
    (chainId, searchValue) => {
      let filtered = availableCurrencies.filter(
        (currency) => currency.chainId === chainId
      );

      if (searchValue) {
        filtered = filtered.filter((currency) =>
          currency.ticker.toLowerCase().includes(searchValue.toLowerCase())
        );
      }

      setFilteredCurrencies(filtered);
    },
    [availableCurrencies]
  );

  useEffect(() => {
    if (availableChains.length > 0) {
      const options = availableChains.map((chain) => ({
        value: chain.chainId,
        label: chain.displayName,
        logo: chain.logo,
      }));
      setChainOptions(options);
      // Find 'BTC' chain by label or value, whichever applies
      const btcChain = options.find(
        (chain) => chain.label === "AVAX" || chain.label === "Avalanche"
      );
      if (btcChain) {
        setSelectedChain(btcChain);
        filterCurrencies(btcChain.value, "");
      }
    }
  }, [availableChains, availableCurrencies, filterCurrencies]);

  const handleChainChange = (selectedOption) => {
    setSelectedChain(selectedOption);
    setSearch("");
    filterCurrencies(selectedOption.value, "");
  };

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    setSearch(searchQuery);

    if (selectedChain) {
      filterCurrencies(selectedChain.value, searchQuery);
    }
  };

  // Custom Option component to show the options with logos
  const Option = (props) => (
    <components.Option {...props}>
      <img
        src={props.data.logo}
        alt={props.data.label}
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
      {props.data.label}
    </components.Option>
  );

  // Custom SingleValue component to show selected option with logo
  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <img
        src={props.data.logo}
        alt={props.data.label}
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
      {props.data.label}
    </components.SingleValue>
  );

  return (
    <>
      {!availableChains.length ? (
        <DataLoader />
      ) : (
        <div className="main-area px-2">
          <h1 className="text-white w-full text-center text-2xl my-4 ">
            Available Networks / Currencies
          </h1>
          <div
            style={{ background: "rgb(41, 41, 41)", minHeight: "85vh" }}
            className="py-8 mt-6 rounded-md"
          >
            <div
              className="flex items-center justify-center flex-col sm:flex-row sm:h-11 m-auto"
              style={{ maxWidth: "100%" }}
            >
              <Select
                options={chainOptions}
                value={selectedChain}
                onChange={handleChainChange}
                components={{ Option, SingleValue }}
                styles={selectStyles}
                menuPlacement="auto"
                menuPosition="fixed"
              />
              <input
                type="text"
                className="h-10 rounded-lg ml-0 text-white mt-4 p-2 sm:mt-0 w-full sm:w-auto sm:ml-2"
                style={{ backgroundColor: "rgb(20,20,20)", width: "300px" }}
                placeholder={
                  selectedChain
                    ? `Search tokens on ${selectedChain.label}...`
                    : "Choose a chain..."
                }
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            <div className="px-6">
              <table className="w-full sm:w-3/4 lg:w-1/3 leading-10 relative m-auto mt-4">
                <thead>
                  <tr style={{ background: "#292929" }}>
                    {/* Merge the first two columns for Currency header */}
                    <th
                      className="py-6 text-xs text-gray-500 tracking-wider text-left"
                      colSpan="2"
                      style={{ width: "53%" }}
                    >
                      Currency
                    </th>
                    <th
                      className="py-6 text-xs text-gray-500 tracking-wider text-right"
                      style={{ width: "47%" }}
                    >
                      Ticker
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCurrencies.length > 0 &&
                    filteredCurrencies.map((currency) => (
                      <tr
                        className="border-b-2"
                        style={{ borderColor: "rgb(20,20,20)" }}
                        key={`${currency.fullName}-${currency.address}`}
                      >
                        <td>
                          <img
                            src={currency.image}
                            alt={currency.ticker}
                            height="28px"
                            width="28px"
                            style={{ borderRadius: "100%" }}
                          />
                        </td>
                        <td className="text-white text-left">
                          {currency.name}
                        </td>
                        <td className="text-white text-right">
                          {currency.ticker}
                        </td>
                      </tr>
                    ))}
                  {filteredCurrencies.length === 0 && (
                    <tr>
                      <td
                        colSpan="3"
                        className="text-white text-center"
                      >
                        No results found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Styles for the Select dropdown
const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 200,
    backgroundColor: "rgb(20, 20, 20)",
    border: state.isFocused ? "1px solid #24ae7a" : "none",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    padding: 2,
    boxShadow: state.isFocused ? "none" : null, // Remove box-shadow when not focused
    transition: "border-color 0.3s ease",
    "&:hover": {
      borderColor: "#24ae7a", // Green border on hover
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "rgb(20, 20, 20)",
    borderRadius: "8px",
    zIndex: 9999,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? "#24ae7a" : "rgb(20, 20, 20)",
    color: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "#24ae7a",
      color: "rgba(255, 255, 255, 1)",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 1)",
  }),
};

export default AvailableCoinPairsPage;
