import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import axios from "axios";
import { apiRequestLimits } from "../store/actions";
import { useDispatch } from "react-redux";

const ApiReference = () => {
    const dispatch = useDispatch();
    const [spec, setSpec] = useState(null);
    const [err, setErr] = useState(null);

    // Fetch the Swagger specification
    useEffect(() => {
        axios("https://ppapi.instaswap.com/instaswap-api-swagger.php?hash=" + localStorage.hash)
            .then((res) => {
                setSpec(res.data);
            })
            .catch((err) => {
                setErr(
                    err.message
                        ? err.message
                        : "Something went wrong, please refresh the page."
                );
            });
    }, []);

    useEffect(() => {
        dispatch(apiRequestLimits());
    }, [dispatch]);

    // Function to generate a unique nonce
    const generateNonce = () => {
        return (Math.floor(Math.random() * 1000000) + Date.now()).toString();
    };

    // Function to generate the message
    const generateMessage = (nonce, url) => {
        const urlObject = new URL(url);
        const apiPath = urlObject.pathname; // Extracts the path from the URL

        // Combine nonce and API path to create the message
        return apiPath + nonce;
    };

    return (
        <>
            <Helmet>
                <title>API Reference | Instaswap Partners</title>
            </Helmet>
            <div className="md:p-0 p-2 text-4xl mt-6 ">
                {/* Render Swagger UI with request interceptor */}
                {spec && (
                    <SwaggerUI
                        spec={spec}
                        requestInterceptor={(request) => {
                            const nonce = generateNonce(); // Generate nonce
                            const message = generateMessage(nonce, request.url); // Generate message based on nonce and request URL

                            // Automatically add the nonce and message headers to every request
                            request.headers["X-Instaswap-Api-Nonce"] = nonce;
                            request.headers["X-Instaswap-Api-Message"] = message;
                            request.headers["X-Instaswap-Swagger"] = true;
                            return request;
                        }}
                    />
                )}
                {err && (
                    <p className="text-white text-xl text-center text-red-500">{err}</p>
                )}
            </div>
        </>
    );
};

export default ApiReference;
