import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { loginUser } from "../store/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import useWindowSize from "../utils/useWindowSize";

function FormikLogin() {
  const {
    values,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Must be more than 6 characters")
        .required("Required"),
    }),
    onSubmit(values) {
      // We added a `username` value for the user which is everything before @ in their email address.
      dispatch(loginUser(values.email, values.password));
    },
  });

  const dispatch = useDispatch();
  const sized = useWindowSize();

  const isLoading = useSelector((state) => state.userReducer.loading);

  useEffect(() => {
    if (values.email === "flaggedemail@mail.com") {
      // It could be a string or any other type
      setFieldValue("isEmailFlagged", { flagged: true, reason: "test" });
    }
  }, [values.email, setFieldValue]);

  return (
    <>
      <div className="formWrapper w-80">
        <form className="baseForm" onSubmit={handleSubmit} noValidate>
          <div className="formRow col-1">
            <div className="form-inputs">
              <label className="form-label" htmlFor="email">
                Email address
              </label>

              <div className="formFieldWrapInner">
                <input
                  type="email"
                  id="email"
                  className="form-input"
                  placeholder={sized.width < 768 ? "Email address" : ""}
                  {...getFieldProps("email")}
                />
              </div>
              <div className="errorMessage">
                {touched["email"] && errors["email"]}
              </div>
            </div>
          </div>
          <div className="form-inputs">
            <label className="form-label" htmlFor="password">
              Password
            </label>

            <div className="formFieldWrapInner">
              <input
                type="password"
                id="password"
                placeholder={sized.width < 768 ? "Password" : ""}
                className="form-input"
                {...getFieldProps("password")}
              />
            </div>
            <div className="errorMessage">
              {touched["password"] && errors["password"]}
            </div>
          </div>
          <div className="text-sm form-forgot-password">
            <Link to="/forget-password" className="font-medium">
              Forgot your password?
            </Link>
          </div>

          <button
            className="btn-more"
            type="submit"
            style={
              !values.email && !values.password ? { opacity: "0.5" } : null
            }
          >
            {isLoading ? (
              <FontAwesomeIcon color="white" icon={faSpinner} spin />
            ) : (
              <span>Login</span>
            )}
          </button>
        </form>
      </div>
    </>
  );
}

export default FormikLogin;
