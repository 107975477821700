import React from "react";
import { createRoot } from 'react-dom/client'; 
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
//TODOGZ reenable
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import store from "./store";
import Provider from "react-redux/es/components/Provider";
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/* ToastContainer is a required component for react-toastify */}
      <ToastContainer 
        position="top-center" // Example position; change as needed
        autoClose={10000} // Example timeout duration; change as needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // Example theme; change as needed
      />
    </BrowserRouter>
  </Provider>,
);
