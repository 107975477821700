import React, { useEffect, useState } from "react";
import "../scss/index.scss";
import { CSSTransition } from "react-transition-group";
import { useSelector, useDispatch } from "react-redux";
import {
  getWithdrawHistory,
  getActiveWallet,
  getVolumes,
  withdrawFees,
  apiRequestLimits,
} from "../store/actions/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import DataLoader from "../components/globalView/DataLoader";
import useWindowSize from "../utils/useWindowSize";
import TableDataWithdraws from "../components/tables/TableDataWithdraws";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MiddleEllipsis from "react-middle-ellipsis";

const Balances = () => {
  const dispatch = useDispatch();
  const sized = useWindowSize();

  const [tableOpen, setTableOpen] = useState("");

  const withdrawHistory = useSelector(
    (state) => state.userReducer.withdrawHistory
  );
  const volumes = useSelector((state) => state.userReducer.volumes);
  const activeWallet = useSelector((state) => state.userReducer.activeWallet);
  const withdrawFeesMsg = useSelector(
    (state) => state.userReducer.withdrawFeesMsg
  );
  const isLoading = useSelector((state) => state.userReducer.loading);
  const customLoading = useSelector((state) => state.userReducer.customLoader);

  useEffect(() => {
    dispatch(getWithdrawHistory());
    dispatch(getActiveWallet());
    dispatch(getVolumes());
    dispatch(apiRequestLimits());
  }, [dispatch]);


  const currentDiv = (id) => {
    setTableOpen(id);
  };

  const requestWithdrawFees = () => {
    dispatch(withdrawFees());
  };

  return (
    <>
      <Helmet>
        <title>Balances | Instaswap Partners</title>
      </Helmet>
      {isLoading ? (
        <DataLoader />
      ) : (
        <CSSTransition
          timeout={{
            enter: 8500,
            exit: 500,
          }}
          classNames="item"
          key="transactions"
        >
          <div className="px-0 main-area extraMn">
            <>
              {/* The Top Section - Withdraw History and Button */}

              <div className="flex md:flex-row flex-col justify-between items-center px-2" style={{marginBottom:'15px'}}>
                <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                  <h1 className="text-4xl text-white w-full text-center">Balances</h1>
                </div>
              </div>

              <div className="">
                <div
                    className="text-md text-white p-3 rounded-md md:mt-0 mt-4"
                    style={sized.width > 768 ? ({
                      background: "#292929",
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      alignItems: 'center',
                      marginBottom: '50px',
                    }) : ({
                          background: "#292929",
                          display: 'flex',
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          marginBottom: '50px',
                          placeContent: 'center space-between'
                        }
                    )}
                >

                  <div style={{width:'100%'}}>
                    <p className="py-1">
                      Balance in{" "}
                      <span className="font-semibold">RUNE</span> :{" "}
                      <span style={{ color: "#24ae7a" }}>
                      {volumes.balanceInRune}
                    </span>
                    </p>
                    <p className="py-1">
                      Approx Balance in{" "}
                      <span className="font-semibold">USD</span> :{" "}
                      <span style={{ color: "#24ae7a" }}>
                      {volumes.balanceInUsd}
                    </span>
                    </p>
                    <p className="py-1">
                      <span className="font-semibold">Approx BTC</span> to
                      receive:{" "}
                      <span style={{ color: "#24ae7a" }}>
                      {volumes.balanceInBtc}
                    </span>
                    </p>

                    <Link to="/edit-wallet">
                      <div className="flex items-center">
                        Selected Wallet:{" "}
                        <div className="font-semibold text-white p-2 flex items-center xl:w-1/4 md:w-1/3 w-28">
                          <img
                              src="https://web.instaswap.com/images/products/small-389ee44692b3dc76b3a1b32d537b260a.png"
                              width="25px"
                              height="25px"
                              alt="BTC"
                          />
                          &nbsp;
                          <MiddleEllipsis>
                          <span className="hover:text-green-600">
                            {activeWallet}
                          </span>
                          </MiddleEllipsis>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <button
                        className="text-lg text-white p-2 rounded-md mt-2"
                        style={
                          parseFloat(volumes.balanceInBtc) === 0 &&
                          parseFloat(volumes.balanceInUsd) === 0
                              ? {
                                cursor: "not-allowed",
                                backgroundColor: "#24ae7a",
                              }
                              : { cursor: "pointer", backgroundColor: "#24ae7a" }
                        }
                        onClick={requestWithdrawFees}
                        disabled={
                          parseFloat(volumes.balanceInBtc) > 0 &&
                          parseFloat(volumes.balanceInUsd) > 0
                              ? false
                              : true
                        }
                    >
                      {isLoading ? (
                          <FontAwesomeIcon
                              color="white"
                              icon={faSpinner}
                              spin
                          />
                      ) : (
                          <span>Withdraw Affiliate Fees</span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="text-green-500 text-right p-2" style={{marginTop: '-40px'}}>
                  &nbsp;
                {withdrawFeesMsg && !isLoading && (
                    <span>{withdrawFeesMsg}</span>
                )}
                </div>
              </div>
              <div className="flex md:flex-row flex-col justify-between items-center px-2">
                <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                  <h1 className="text-4xl text-white">Withdraw History</h1>
                </div>
              </div>


              {/* Table Section - Conditionally Display Withdraw History or No Data Message */}
              <div className="mx-auto px-2 mt-4">
                {withdrawHistory && withdrawHistory.length > 0 ? (
                  <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table
                      className="min-w-full leading-normal relative"
                      style={
                        customLoading === true ? { height: "323px" } : null
                      }
                    >
                      {sized.width > 768 ? (
                        <thead>
                          <tr
                            style={{
                              background: "#292929",
                              borderBottom: "2px solid #1D1D1D",
                            }}
                          >
                            <th className="md:px-2 px-5 py-3 text-left text-xs text-gray-500  tracking-wider">
                              ID
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Withdraw Amount
                            </th>

                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Withdraw Wallet
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Date
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Status
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Profits
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr
                            style={{
                              background: "#292929",
                              borderBottom: "2px solid #1D1D1D",
                            }}
                          >
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Withdraw History details
                            </th>
                          </tr>
                        </thead>
                      )}

                      {withdrawHistory &&
                        withdrawHistory.length > 0 &&
                        withdrawHistory.map((item) => (
                          <TableDataWithdraws
                            key={item.id}
                            item={item}
                            tableOpen={tableOpen}
                            currentDiv={currentDiv}
                          />
                        ))}
                    </table>
                  </div>
                ) : (
                  <div className="inline-block min-w-full shadow rounded-lg overflow-hidden box empty-table">
                    <h5 className="text-white text-center p-4">No Withdrawals Yet</h5>
                  </div>
                )}
              </div>
            </>
          </div>
        </CSSTransition>
      )}
    </>
  );
};

export default Balances;
