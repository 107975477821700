import * as Actions from "../actions";

const initialState = {
  loading: false,
  whitelisted: [],
  customLoader: false,
  authLoading: false,
  user: {},
  authenticated: false,
  availablePermissions: [],
  volumes: [],
  transactionsChartData: [],
  transactions: [],
  withdrawHistory: [],
  activeWallet: "",
  withdrawFeesMsg: "",
  errorMsg: "",
  apiRequests: [],
  page: "overview",
  updatedClientEmailResponse: "",
  updatedWhitelistedIpsResponse: "",
  availableChains: [],
  availableCurrencies: [],
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN_USER: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        authenticated: true,
      };
    }
    case Actions.LOGOUT_USER:
      return {
        ...state,
        authenticated: false,
      };
      case Actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordResponse: action.payload,
        changePasswordError: null,
      };
    case Actions.AVAILABLE_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case Actions.GET_VOLUMES: {
      return {
        ...state,
        volumes: action.payload,
      };
    }
    case Actions.GET_TRANSACTIONS_CHART_DATA: {
      return {
        ...state,
        transactionsChartData: action.payload,
      };
    }
    case Actions.API_REQUEST_LIMITS: {
      return {
        ...state,
        apiRequests: action.payload,
      };
    }
    case Actions.GET_USER_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
      };
    }
    case Actions.GET_USER_WITHDRAW_HISTORY: {
      return {
        ...state,
        withdrawHistory: action.payload,
      };
    }
    case Actions.GET_ACTIVE_WALLET: {
      return {
        ...state,
        activeWallet: action.payload,
      };
    }
    case Actions.CHANGE_WALLET: {
      return {
        ...state,
        activeWallet: action.payload,
      };
    }
    case Actions.WITHDRAW_FEES: {
      return {
        ...state,
        withdrawFeesMsg: action.payload,
      };
    }
    case Actions.LOADING_STATE: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.CUSTOM_LOADER: {
      return {
        ...state,
        customLoader: action.payload,
      };
    }
    case Actions.CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case Actions.AUTHENTICATED_USER: {
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        authLoading: false,
      };
    }

    case Actions.UPDATE_CLIENT_EMAIL:
      return {
        ...state,
        updatedClientEmailResponse: action.payload,
      };
    case Actions.CHANGE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMsg: action.payload,
      };
    }
    case Actions.AVAILABLE_CHAINS: {
      return {
        ...state,
        availableChains: action.payload,
      };
    }
    case Actions.AVAILABLE_CURRENCIES: {
      return {
        ...state,
        availableCurrencies: action.payload,
      };
    }
    case Actions.AUTH_LOADING: {
      return {
        ...state,
        authLoading: action.payload,
      };
    }
    case Actions.UPDATE_BPS: {
      return {
        ...state,
        user: {
          ...state.user,
          response: {
            ...state.user.response,
            bps: action.payload,
          },
        },
      };
    }
    case Actions.GET_WHITELISTED_IPS:
      return {
        ...state,
        whitelistedIps: action.payload,
      };
    case Actions.ADD_WHITELISTED_IP:
      return {
        ...state,
        loading: false,
        updatedWhitelistedIpsResponse: action.payload,
        whitelistedIps: [...state.whitelistedIps, action.payload],
      };
    case Actions.DELETE_WHITELISTED_IP:
      return {
        ...state,
        whitelistedIps: state.whitelistedIps.filter(ip => ip !== action.payload),
      };

    default: {
      return state;
    }
  }
};

export default userReducer;
