import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {getTransactions, updateClientEmail} from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangeClientEmail = ({ item, setIsOpen }) => {
  const { handleSubmit, touched, errors } = useFormik({
    initialValues: {
      transactionId: item.id,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address"),
    }),
    onSubmit(values) {
      dispatch(updateClientEmail(email, values.transactionId));
       setTimeout(() => {
        /* window.location.reload();*/
         dispatch(getTransactions(null, 8));
         setIsOpen(false);
       }, 2000);
    },
  });

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const isLoading = useSelector((state) => state.userReducer.loading);

  const updatedClientEmailResponse = useSelector(
    (state) => state.userReducer.updatedClientEmailResponse
  );

  return (
    <div className="border-radius update__clientEmail bg-cream leading-normal overflow-x-hidden lg:overflow-auto z-40">
      <div className="flex-1 md:p-0 flex flex-col">
        <div className="bg-cream-lighter p-4 shadow">
          <div>
            <div className="md:flex mb-8">
              <div className="md:flex-1 mt-2 mb:mt-0 md:px-3 text-center">
                {updatedClientEmailResponse ===
                "Client Email updated on this transaction" ? (
                  <div className="flex justify-center flex-col items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="green"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontSize: "50px",
                      }}
                    />
                    <h4>Client Email updated on this transaction!</h4>
                  </div>
                ) : (
                  <div>
                    <h3 className="mb-2">
                      Transaction Id{" "}
                      <span
                        className="font-semibold"
                        style={{ color: "#146755" }}
                      >
                        #{item.id}
                      </span>{" "}
                    </h3>
                    <form onSubmit={handleSubmit}>
                      <div className="md:flex-1 mt-2 mb:mt-0">
                        <label
                          className="form-label float-left"
                          htmlFor="email"
                        >
                          Email address
                        </label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          className="rounded w-full shadow-inner p-4 border-0"
                          placeholder="Client Email..."
                          type="email"
                          id="email"
                          value={email}
                        ></input>
                      </div>
                      <div className="errorMessage">
                        {touched["email"] && errors["email"]}
                      </div>
                      <div className="md:flex-1">
                        <div
                          className="btn-submit mt-3"
                          style={email === "" ? { opacity: "0.5" } : null}
                        >
                          <button
                            disabled={email === "" ? true : false}
                            type="submit"
                          >
                            {isLoading ? (
                              <FontAwesomeIcon icon={faSpinner} color="white" />
                            ) : (
                              "Update Client Email"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeClientEmail;
