import React, { useState } from "react";
import "../../scss/index.scss";
import useWindowSize from "../../utils/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const TableDataWithdraws = ({ item, currentDiv, tableOpen }) => {
  const sized = useWindowSize();

  const [openDiv, setOpenDiv] = useState(false);

  const openTable = () => {
    if (tableOpen === "") {
      setOpenDiv(true);
      currentDiv(item.id, openDiv);
    } else if (tableOpen === item.id) {
      setOpenDiv(!openDiv);
    } else if (tableOpen !== "" && tableOpen !== item.id) {
      setOpenDiv(true);
      currentDiv(item.id);
    }
  };

  return (
    <tbody>
      {sized.width > 768 ? (
        <tr style={{ background: "#292929", color: "white" }}>
          <td
            className={`md:px-2 md:py-4 md:border-b
         border-gray-200 text-sm relative text-left`}
          >
            #{item.id}
          </td>
          <td
            className={`md:px-2 md:py-4
            md:border-b
          border-gray-200 text-sm relative text-left`}
          >
            {item.withdrawAmount}&nbsp;BTC
          </td>
          <td
            className={`md:px-2 md:py-4
            md:border-b
          border-gray-200 text-sm relative text-left`}
          >
            {item.withdrawWallet}
          </td>

          <td
            className={`md:px-2 md:py-4 md:border-b
         border-gray-200 text-sm relative`}
          >
            {item.dateTime}
          </td>

          <td
            className={`md:px-2 md:py-4 md:border-b
          border-gray-200 text-sm relative`}
          >
            <span className="relative inline-block py-1 font-semibold">
              <span className="relative">
                {item.status === "Deposit Not Completed" ? (
                  <p className="btn-error text-xs">{item.status}</p>
                ) : item.status === "done" ? (
                  <p className="btn-success text-xs">{item.status}</p>
                ) : (
                  <p className="btn-pending text-xs">{item.status}</p>
                )}
              </span>
            </span>
          </td>
          <td
            className={`md:px-2 md:py-4 md:border-b
         border-gray-200 text-sm relative`}
          >
            {item.withdrawAmountInUsd}$
          </td>
        </tr>
      ) : (
        <>
          <tr
            style={{ background: "#292929", color: "white" }}
            onClick={openTable}
          >
            <td
              className={`py-5
            border-b
          border-gray-200 text-sm relative`}
            >
              <div
                className={`flex items-center justify-around ${
                  openDiv === true &&
                  tableOpen === item.id &&
                  `table__data__mb60`
                }`}
              >
                <div style={{ marginLeft: "0px" }}>#{item.id}</div>

                <div className="text-center">
                  <p className="whitespace-no-wrap" style={{ color: "white" }}>
                    {item.withdrawAmount} &nbsp;BTC
                  </p>
                  <span>
                    {item.status === "Deposit Not Completed" ? (
                      <p className="btn-error text-xs">{item.status}</p>
                    ) : item.status === "Completed" ? (
                      <p className="btn-success text-xs">{item.status}</p>
                    ) : (
                      <p className="btn-pending text-xs">{item.status}</p>
                    )}
                  </span>
                </div>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    color="white"
                    icon={!openDiv ? faChevronDown : faChevronUp}
                    className="ml-2"
                  />
                </div>
              </div>

              {openDiv && tableOpen === item.id && (
                <div className="table__data__mobile mb-2">
                  <div
                    className={`flex mt-4 items-center expand absolute`}
                    style={{ marginLeft: "15px" }}
                  >
                    {" "}
                    <p
                      className="whitespace-no-wrap"
                      style={{ color: "white" }}
                    >
                      Withdraw Wallet:{" "}
                      <span className="font-semibold">
                        {item.withdrawWallet}
                      </span>
                    </p>
                  </div>
                  <div
                    className={`flex mt-4 items-center expand absolute`}
                    style={{ marginLeft: "15px", height: "120px" }}
                  >
                    <p>
                      Date: <span className="font-bold">{item.dateTime}</span>
                    </p>
                  </div>
                  <div
                    className="flex mt-2 items-center expand absolute"
                    style={{ marginLeft: "15px", height: "198px" }}
                  >
                    Profits: &nbsp;
                    <span className="font-bold">
                      {item.withdrawAmountInUsd}$
                    </span>
                  </div>
                </div>
              )}
            </td>
          </tr>
        </>
      )}
    </tbody>
  );
};

export default TableDataWithdraws;
