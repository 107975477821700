import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import DataLoader2 from "../components/globalView/DataLoader2";

const PrivateRoute = () => {
  const authenticated = useSelector((state) => state.userReducer.authenticated);
  const authLoading = useSelector((state) => state.userReducer.authLoading);

  if (authLoading) {
    return <DataLoader2 />;
  }

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;