import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./scss/index.scss";
import AuthPage from "./pages/AuthPage";
import Login from "./pages/Login";
import PrivateRoute from "./pages/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import { authenticateUser, changePage } from "./store/actions";
import DataLoader2 from "../src/components/globalView/DataLoader2";

const App = () => {
  const dispatch = useDispatch();
  const [initializing, setInitializing] = useState(true);
  const [hash, setHash] = useState(null);
  const user = useSelector((state) => state.userReducer.user);
  const errorMsg = useSelector((state) => state.userReducer.errorMsg);
  const authenticated = useSelector((state) => state.userReducer.authenticated);
  const authLoading = useSelector((state) => state.userReducer.authLoading);

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg);
    }
  }, [errorMsg]);

  useEffect(() => {
    const initializeAuth = async () => {
      const storedHash = localStorage.getItem("hash");
      const lastPage = localStorage.getItem("lastPage");
      const username = localStorage.getItem("username");

      if (storedHash) {
        setHash(storedHash);
        await dispatch(authenticateUser(storedHash, username));
      }

      if (lastPage) {
        dispatch(changePage(lastPage));
      }

      setInitializing(false);
    };

    initializeAuth();

    const interval = setInterval(() => {
      const lastLogin = localStorage.getItem("expirationTime");
      if (lastLogin && new Date().getTime() > lastLogin) {
        localStorage.clear();
        window.location.reload();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  if (initializing || authLoading) {
    return <DataLoader2 />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={authenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoute user={user} hash={hash} />}>
          <Route path="*" element={<AuthPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;