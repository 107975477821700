import React, { useEffect } from "react";
import Layout from "../components/globalView/Layout";
import { useNavigate } from "react-router-dom";
import "../scss/index.scss";

const AuthPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const lastPage = localStorage.getItem("lastPage");

    navigate(`/${lastPage || "overview"}`); // Use navigate instead of props.history.push
    // eslint-disable-next-line
  }, [navigate]); // Include navigate in dependency array

  return (
    <div>
      <Layout />
    </div>
  );
};

export default AuthPage;
