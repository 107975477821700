import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import "../scss/index.scss";
import { apiRequestLimits, changePassword } from "../store/actions";
import useWindowSize from "../utils/useWindowSize";
import { Helmet } from "react-helmet";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const user = useSelector((state) => state.userReducer.user);
  const isLoading = useSelector((state) => state.userReducer.loading);
  const changePasswordResponse = useSelector(
      (state) => state.userReducer.changePasswordResponse
  );
  const changePasswordError = useSelector(
      (state) => state.userReducer.changePasswordError
  );

  const sized = useWindowSize();

  const handleSubmit = () => {
    if (newPassword === repeatNewPassword && currentPassword) {
      dispatch(changePassword(currentPassword, newPassword, repeatNewPassword));
    } else {
      setErrorMessage("Passwords must match.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(apiRequestLimits());
  }, [dispatch]);

  // Use useEffect to watch for changes in the response and error
  useEffect(() => {
    if (changePasswordResponse) {
      if(changePasswordResponse.response === 'User updated successfully'){
        setSuccessMessage(changePasswordResponse.response || "Password changed successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setRepeatNewPassword("");
      }else{
        setErrorMessage(changePasswordResponse.response || "Failed to change password!");
      }
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
    }

    if (changePasswordError) {
      setErrorMessage(changePasswordError.message || "An error occurred.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [changePasswordResponse, changePasswordError]);

  return (
      <>
        <Helmet>
          <title>Change Password | Instaswap Partners</title>
        </Helmet>

        <div className="px-0 main-area extraMn">
          <div className="flex md:flex-row flex-col justify-between items-center px-2">
            <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
              <h1 className="text-4xl text-white w-full text-center">Change Password</h1>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-start h-2/4 mt-20">
          <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
            >
              <div>
                <img
                    style={{ width: "150px", marginTop: "40px" }}
                    src={user.response.partnerImage}
                    alt="Partner"
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <p style={{ color: "white", fontSize: "25px" }}>
                  {user.response.partnerName}
                </p>
              </div>
            </div>
            <div className="mb-4 mt-10">
              <label className="form-label">Your current password</label>
              <input
                  className="form-input"
                  type="password"
                  name="currentPassword"
                  placeholder={sized.width < 768 ? "Current Password" : ""}
                  value={currentPassword}
                  required
                  onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">Your new password</label>
              <input
                  className="form-input"
                  type="password"
                  name="newPassword"
                  placeholder={sized.width < 768 ? "New Password" : ""}
                  value={newPassword}
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div>
              <label className="form-label">Repeat new password</label>
              <input
                  className="form-input"
                  type="password"
                  name="repeatNewPassword"
                  placeholder={sized.width < 768 ? "Repeat New Password" : ""}
                  value={repeatNewPassword}
                  required
                  onChange={(e) => setRepeatNewPassword(e.target.value)}
              />
            </div>

            <button className="btn-more" onClick={handleSubmit}>
              {isLoading ? (
                  <FontAwesomeIcon color="white" icon={faSpinner} spin />
              ) : (
                  <span>Change password</span>
              )}
            </button>
            {errorMessage && (
                <div className="text-red-500 bg-gray-200 w-full text-center p-2 mt-2">
                  {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="text-green-500 bg-gray-200 w-full text-center p-2 mt-2">
                  {successMessage}
                </div>
            )}
          </div>
        </div>
      </>
  );
};

export default ChangePassword;
