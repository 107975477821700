import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/instaswapFullLogoSmall.webp";
import {
    Sidebar,
    Menu,
    MenuItem,
    SubMenu,
} from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faRetweet,
    faUniversity,
    faCode,
    faSignOutAlt,
    faCogs,
    faHeart,
    faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { changePage, logout } from "../../store/actions";
import '../../scss/_sidebar.scss';
import useWindowSize from "../../utils/useWindowSize"; // Import the CSS file



const MySidebar = ({ toggled, handleToggleSidebar }) => {
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current location
    const [hover, setHover] = useState(false);
    const sized = useWindowSize();

    const handleLogout = () => {
        localStorage.removeItem("expirationTime");
        localStorage.clear();
        window.location.reload();
        setTimeout(() => {
            dispatch(logout());
        }, 1000);
    };

    const handleChangePage = (value) => {
        dispatch(changePage(value));
    };

    // Function to check if a route is active
    const isActive = (path) => location.pathname === path;

    return (
        <>
            {sized.width < 769 ? (
                <div className="form-content-left">
                    <Sidebar
                        toggled={toggled}
                        breakPoint="md"
                        onToggle={handleToggleSidebar}
                        backgroundColor="#2d2d2d"
                        rootStyles={{
                            color: "#fff",
                            borderRight: "none",
                        }}
                    >
                        <div
                            style={{
                                padding: "24px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: "1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            className="uppercase text-grey p-2 mx-2 text-3xl hover:text-blue-light"
                        >
                            <img src={logo} alt="logo" />
                        </div>

                        <Menu
                            iconShape="circle"
                            menuItemStyles={{
                                button: {
                                    "&:hover": {
                                        backgroundColor: "#5d5d5d",
                                    },
                                },
                                subMenuContent: {
                                    backgroundColor: "#3d3d3d",
                                },
                            }}
                        >
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faHome} />}
                                component={<Link to="/overview" />}
                                onClick={() => {
                                    handleChangePage("overview");
                                    handleToggleSidebar();
                                }}
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faRetweet} />}
                                component={<Link to="/transactions" />}
                                onClick={() => {
                                    handleChangePage("transactions");
                                    handleToggleSidebar();
                                }}
                            >
                                Transaction History
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUniversity} />}
                                component={<Link to="/balances" />}
                                onClick={() => {
                                    handleChangePage("balances");
                                    handleToggleSidebar();
                                }}
                            >
                                Balances
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                            >
                                API Reference
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCoins} />}
                                component={<Link to="/available-coins-pairs" />}
                                onClick={() => {
                                    handleChangePage("available-coins-pairs");
                                    handleToggleSidebar();
                                }}
                            >
                                Available Coins / Pairs
                            </MenuItem>

                            <SubMenu
                                label="Settings"
                                icon={<FontAwesomeIcon icon={faCogs} />}
                                style={{ backgroundColor: hover ? "#5d5d5d" : "#2d2d2d" }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >

                                <MenuItem
                                    component={<Link to="/whitelisted-ips" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-ips");
                                        handleToggleSidebar();
                                    }}
                                >
                                    Whitelisted IPs
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/fee-management" />}
                                    onClick={() => {
                                        handleChangePage("fee-management");
                                        handleToggleSidebar();
                                    }}
                                >
                                    Fee Management
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/edit-wallet" />}
                                    onClick={() => {
                                        handleChangePage("edit-wallet");
                                        handleToggleSidebar();
                                    }}
                                >
                                    Wallet Management
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/change-password" />}
                                    onClick={() => {
                                        handleChangePage("change-password");
                                        handleToggleSidebar();
                                    }}
                                >
                                    Change Password
                                </MenuItem>

                            </SubMenu>
                        </Menu>

                        <div
                            className="wrap sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                            }}
                        >
                            <button
                                style={{ width: "90%" }}
                                className="sidebar-btn logout"
                                rel="noopener noreferrer"
                                onClick={handleLogout}
                            >
                                <FontAwesomeIcon color="red" icon={faSignOutAlt} />
                                <span>Logout</span>
                            </button>
                        </div>

                        <div
                            style={{
                                padding: "20px 24px",
                                fontSize: "12px",
                                textAlign: "center",
                            }}
                        >
                            Made with &nbsp;
                            {<FontAwesomeIcon color="red" icon={faHeart} />} &nbsp; for our <br />
                            Instaswap Partners
                        </div>
                    </Sidebar>
                </div>
            ) : (
                <div className="form-content-left">
                    <Sidebar
                        toggled={toggled}
                        breakPoint="md"
                        onToggle={handleToggleSidebar}
                        backgroundColor="#2d2d2d"
                        rootStyles={{
                            color: "#fff",
                            borderRight: "none",
                            height: '100%',
                        }}
                    >
                        <div
                            style={{
                                padding: "24px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: "1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            className="uppercase text-grey p-2 mx-2 text-3xl hover:text-blue-light"
                        >
                            <img src={logo} alt="logo" />
                        </div>

                        <Menu
                            iconShape="circle"
                            menuItemStyles={{
                                button: {
                                    "&:hover": {
                                        backgroundColor: "#5d5d5d",
                                    },
                                },
                                subMenuContent: {
                                    backgroundColor: "#3d3d3d",
                                },
                            }}
                        >
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faHome} />}
                                component={<Link to="/overview" />}
                                onClick={() => {
                                    handleChangePage("overview");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/overview")}
                                style={{
                                    backgroundColor: isActive("/overview") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faRetweet} />}
                                component={<Link to="/transactions" />}
                                onClick={() => {
                                    handleChangePage("transactions");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/transactions")}
                                style={{
                                    backgroundColor: isActive("/transactions") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Transaction History
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUniversity} />}
                                component={<Link to="/balances" />}
                                onClick={() => {
                                    handleChangePage("balances");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/balances")}
                                style={{
                                    backgroundColor: isActive("/balances") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Balances
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/api-reference")}
                                style={{
                                    backgroundColor: isActive("/api-reference") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                API Reference
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCoins} />}
                                component={<Link to="/available-coins-pairs" />}
                                onClick={() => {
                                    handleChangePage("available-coins-pairs");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/available-coins-pairs")}
                                style={{
                                    backgroundColor: isActive("/available-coins-pairs") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Available Networks
                            </MenuItem>
                            <SubMenu
                                label="Settings"
                                icon={<FontAwesomeIcon icon={faCogs} />}
                                style={{ backgroundColor: hover ? "#5d5d5d" : "#2d2d2d" }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >


                                <MenuItem
                                    component={<Link to="/whitelisted-ips" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-ips");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/whitelisted-ips")}
                                    style={{
                                        backgroundColor: isActive("/whitelisted-ips") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    Whitelisted IPs
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/fee-management" />}
                                    onClick={() => {
                                        handleChangePage("fee-management");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/fee-management")}
                                    style={{
                                        backgroundColor: isActive("/fee-management") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    Fee Management
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/edit-wallet" />}
                                    onClick={() => {
                                        handleChangePage("edit-wallet");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/edit-wallet")}
                                    style={{
                                        backgroundColor: isActive("/edit-wallet") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    Wallet Management
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/change-password" />}
                                    onClick={() => {
                                        handleChangePage("change-password");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/change-password")}
                                    style={{
                                        backgroundColor: isActive("/change-password") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    Change Password
                                </MenuItem>
                            </SubMenu>
                        </Menu>
                    </Sidebar>
                    <div style={{width: '100%', backgroundColor: '#2d2d2d'}}>
                        <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                            }}
                        >
                            <button
                                style={{ width: "90%" }}
                                className="sidebar-btn logout"
                                rel="noopener noreferrer"
                                onClick={handleLogout}
                            >
                                <FontAwesomeIcon color="red" icon={faSignOutAlt} />
                                <span>Logout</span>
                            </button>
                        </div>

                        <div
                            style={{
                                padding: "20px 24px",
                                fontSize: "12px",
                                textAlign: "center",
                                marginTop: 'auto',
                                color: 'white',
                            }}
                        >
                            Made with &nbsp;
                            {<FontAwesomeIcon color="red" icon={faHeart} />} &nbsp; for our <br />
                            Instaswap Partners
                        </div>
                    </div>
                </div>
            )}
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#5865F2', // Discord color
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
                onClick={() => window.open('https://discord.com/invite/um6NazJSMk', '_blank')}
            >
                <svg style={{width: '45px', height: '45px', marginLeft: '3px',
                }} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bl0iky" focusable="false"
                     aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25"
                     height="25">
                    <path fill="currentColor"
                          d="M9.593 10.971c-.542 0-.969.475-.969 1.055c0 .578.437 1.055.969 1.055c.541 0 .968-.477.968-1.055c.011-.581-.427-1.055-.968-1.055zm3.468 0c-.542 0-.969.475-.969 1.055c0 .578.437 1.055.969 1.055c.541 0 .968-.477.968-1.055c-.001-.581-.427-1.055-.968-1.055z"></path>
                    <path fill="white"
                          d="M17.678 3H4.947A1.952 1.952 0 0 0 3 4.957v12.844c0 1.083.874 1.957 1.947 1.957H15.72l-.505-1.759l1.217 1.131l1.149 1.064L19.625 22V4.957A1.952 1.952 0 0 0 17.678 3zM14.01 15.407s-.342-.408-.626-.771c1.244-.352 1.719-1.13 1.719-1.13c-.39.256-.76.438-1.093.562a6.679 6.679 0 0 1-3.838.398a7.944 7.944 0 0 1-1.396-.41a5.402 5.402 0 0 1-.693-.321c-.029-.021-.057-.029-.085-.048a.117.117 0 0 1-.039-.03c-.171-.094-.266-.16-.266-.16s.456.76 1.663 1.121c-.285.36-.637.789-.637.789c-2.099-.067-2.896-1.444-2.896-1.444c0-3.059 1.368-5.538 1.368-5.538c1.368-1.027 2.669-.998 2.669-.998l.095.114c-1.71.495-2.499 1.245-2.499 1.245s.21-.114.561-.275c1.016-.446 1.823-.57 2.156-.599c.057-.009.105-.019.162-.019a7.756 7.756 0 0 1 4.778.893s-.751-.712-2.366-1.206l.133-.152s1.302-.029 2.669.998c0 0 1.368 2.479 1.368 5.538c0-.001-.807 1.376-2.907 1.443z"></path>
                </svg>
            </div>
        </>
    );
};

export default MySidebar;
