const TableDataVolumes = ({ item }) => {
  return (
    <div
      key={item.id}
      className="mt-4 cursor-pointer text-sm flex justify-between items-center px-0"
    >
      <div className="flex items-center gap-1 justify-center">
        <img src={item.image} width="24px" height="24px" alt="coin-logo"  style={{ borderRadius: "100%" }}/>

        <p>{item.name}</p>
      </div>
      <div>
        <span className="font-semibold">{item.SumProfit.toFixed(8)}</span>{" "}
      </div>
    </div>
  );
};

export default TableDataVolumes;
