import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import "../scss/index.scss";
import { Link } from "react-router-dom";
import useWindowSize from "../utils/useWindowSize";
import mobileLogo from "../images/mobileLogo.png";
import text from "../images/text3.png";
import logo from "../images/test.png";
import { resetPassword } from "../store/actions";
import mobileTaurus from "../images/loginTaurusDesktop2.webp";
import { Helmet } from "react-helmet";


const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  const handleSubmit = (email) => {
    if (email) {
      dispatch(resetPassword(email));
      window.location.href = '/login';
    }
  };
  const isLoading = useSelector((state) => state.userReducer.loading);
  const sized = useWindowSize();

  return (
      <>
        <Helmet>
          {" "}
          <title>Log In | Instaswap Partners</title>{" "}
        </Helmet>
        <div className="form-container">
          <div
              className="form-content-left"
              style={
                sized.width < 768 ? { display: `none` } : null
              }
          >
            <div className="w-full justify-center flex">
              <img src={text} className="form-content-left-text" alt="logo" />

              <img
                  style={sized.width < 769 ? { display: "none" } : null}
                  src={sized.width < 769 ? mobileLogo : logo}
                  alt="logo"
                  className="form-content-left-logo"
              />
            </div>
          </div>
          <div className={sized.width > 769 ? "form-content-right mainBGWhite" : "form-content-right mainBGWhiteMob"}>
            <div style={{ display: "flex", height: '100vh' , flexDirection: 'row' , alignContent: 'center' , alignItems: 'center' , justifyContent: 'center' }}>
              <div style={sized.width < 769 ? { marginTop: "auto", marginBottom: 'auto', backgroundColor: 'rgb(255 255 255 / 50%)', borderRadius: '8px' } : {width: '100%'}}>
                <div className="form">
                  <h2 className="text-center w-full text-3xl font-extrabold text-gray-900">
                    Recover Password
                  </h2>
                  <div className="form-inputs" style={{width:'330px',padding:'20px'}}>
                    <input
                        className="form-input"
                        type="text"
                        name="name"
                        placeholder="Your email here"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <button className="btn-more" style={{width:'300px'}} onClick={() => handleSubmit(email)}>
                    {isLoading ? (
                        <FontAwesomeIcon color="white" icon={faSpinner} spin />
                    ) : (
                        <span>Submit</span>
                    )}
                  </button>
                </div>
                <div className="form-link">
                  {sized.width > 769 ?
                      <p>If you remember your password <Link to="/login">Sign In</Link></p>
                      :
                      <p style={{color:'white'}}>If you remember your password <Link to="/login">Sign In</Link></p>
                  }
                </div>
              </div>
            </div>
            <img
                src={mobileTaurus}
                alt="logo"
                style={sized.width > 769 ? { display: "none" } : { zIndex: "-1" }}
                className="form-content-left-logo"
            />
          </div>
        </div>
      </>
  );
};

export default ForgotPassword;
