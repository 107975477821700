import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  apiRequestLimits,
  getTransactions,
  getTransactionsChartData,
  getVolumes,
} from "../store/actions";
import useWindowSize from "../utils/useWindowSize";
import DataLoader from "../components/globalView/DataLoader";
import TransactionsChart from "../components/charts/TransactionsChart";
import CoinProfitPieChart from "../components/charts/IncomePie";
import TableDataVolumes from "../components/tables/TableDataVolumes";
import { Helmet } from "react-helmet";
import TableDataHomeTransactions from "../components/tables/TableDataHomeTransactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faDollarSign } from "@fortawesome/free-solid-svg-icons";


const Dashboard = () => {
  const dispatch = useDispatch();
  const sized = useWindowSize();

  const [tableOpen, setTableOpen] = useState("");
  const [leftDiv, setLeftDiv] = useState(false);
  const [middleDiv, setMiddleDiv] = useState(false);

  const user = useSelector((state) => state.userReducer.user);

  const isLoading = useSelector((state) => state.userReducer.loading);

  const apiRequests = useSelector((state) => state.userReducer.apiRequests);
  const volumes = useSelector((state) => state.userReducer.volumes);
  const transactions = useSelector((state) => state.userReducer.transactions);
  const transactionsArray =
    transactions &&
    Object.keys(transactions).length > 0 &&
    transactions.transactions;

  useEffect(() => {
    dispatch(getTransactionsChartData());
    dispatch(getTransactions());
    dispatch(getVolumes());
    dispatch(apiRequestLimits());
  }, [dispatch]);

  const currentDiv = (id) => {
    setTableOpen(id);
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>Dashboard | Instaswap Partners</title>{" "}
      </Helmet>
      {isLoading && user && Object.keys(user).length > 0 ? (
        <DataLoader />
      ) : (
        <>
          <div className="px-4 md:px-0 main-area">
            <div className="flex items-center md:flex-row flex-col justify-between">
              {" "}
              <h1 className="text-4xl text-white mb-4">Overview</h1>{" "}
            </div>

            <div
              className="text-md text-white rounded-md mt-4 w-full lg:hidden block p-4"
              style={{ background: "#292929" }}
            >
              <h1 className="font-semibold"> API Requests Stats</h1>
              <hr className="mt-2" />
              <p className="py-1 mt-2">
                Used Requests:{" "}
                <span style={{ color: "#24ae7a" }}>
                  {apiRequests.usedRequests}
                </span>
              </p>
              <p className="py-1">
                Remaining Requests:{" "}
                <span style={{ color: "#24ae7a" }}>
                  {apiRequests.remainingRequests}
                </span>
              </p>
              <p className="py-1">
                Requests Limit:{" "}
                <span style={{ color: "#24ae7a" }}>
                  {apiRequests.requestsLimit}
                </span>{" "}
                <span className="italic"> per hour</span>
              </p>
            </div>

            <div className="dashboard-container">
              {/* Left Container MOBILE*/}
              <div className="flex flex-col xl:hidden mt-4">
                <div
                  style={{ backgroundColor: "#292929" }}
                  className="p-4 rounded-md text-white font-medium  overflow-y-auto my-2"
                  onClick={() => setLeftDiv(!leftDiv)}
                >
                  {volumes &&
                    Object.keys(volumes).length > 0 &&
                    sized.width > 768 && <TransactionsChart />}

                  <div className="flex items-center justify-between lg:mt-4 mt-0">
                    {" "}
                    <h1 className="font-semibold"> Recent Transactions</h1>{" "}
                    <FontAwesomeIcon
                      color="white"
                      icon={!leftDiv ? faChevronDown : faChevronUp}
                      className="ml-2"
                    />
                  </div>

                  <hr className="mt-2" />
                </div>

                {leftDiv && (
                  <table className="min-w-full mb-3">
                    {transactionsArray &&
                      transactionsArray.length > 0 &&
                      transactionsArray.map((item) => (
                        <TableDataHomeTransactions
                          key={item.id}
                          item={item}
                          tableOpen={tableOpen}
                          currentDiv={currentDiv}
                        />
                      ))}
                  </table>
                )}
              </div>

              {/* Left Container DESKTOP*/}
              <div className="text-white font-medium xl:flex flex-col xl:overflow-y-auto mr-4 xl:mb-0 hidden">
                <div
                  className="rounded-md p-4"
                  style={{ backgroundColor: "#292929" }}
                >
                  {volumes &&
                    Object.keys(volumes).length > 0 &&
                    sized.width > 768 && <TransactionsChart />}
                </div>
                <div
                  className="mt-2 rounded-md p-0 md:p-4"
                  style={{
                    backgroundColor: "#292929",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  <h1 className="font-semibold mt-6 mx-4 xl:mx-0">
                    {" "}
                    Recent Transactions
                  </h1>
                  {/* <hr className="mt-2" /> */}
                  <table className="min-w-full">
                    {transactionsArray &&
                      transactionsArray.length > 0 &&
                      transactionsArray
                        .slice(0, 5)
                        .map((item) => (
                          <TableDataHomeTransactions
                            key={item.id}
                            item={item}
                            tableOpen={tableOpen}
                            currentDiv={currentDiv}
                            className="cursor-pointer"
                          />
                        ))}
                  </table>
                </div>
              </div>

              {/* Right Container MOBILE */}
              <div className="block xl:hidden my-4">
                <div
                  style={{ backgroundColor: "#292929" }}
                  className=" text-white font-medium  overflow-y-auto my-2 p-4 rounded-md"
                >
                  <div
                    className="flex items-center justify-between"
                    onClick={() => setMiddleDiv(!middleDiv)}
                  >
                    <span className="font-semibold">Total Income:</span> &nbsp;
                    <div
                        style={{ backgroundColor: "rgba(12,213,111,0.26)", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
                        className="flex items-center rounded-sm p-1.2"
                    >
                      ≅&nbsp;
                      {volumes &&
                        Object.keys(volumes).length > 0 &&
                        volumes.sumBtcVolume.toFixed(1)}{" "}
                      &nbsp;
                      <FontAwesomeIcon
                          color="white"
                          icon={faDollarSign}
                          className="customUsdIcon"
                      />
                    </div>
                    <FontAwesomeIcon
                      color="white"
                      icon={!middleDiv ? faChevronDown : faChevronUp}
                      className="ml-2"
                    />
                  </div>
                  <hr className="mt-2" />
                </div>

                {middleDiv &&
                  volumes.calculatedVolumesPerCoin &&
                  volumes.calculatedVolumesPerCoin.length > 0 &&
                  volumes.calculatedVolumesPerCoin.map((item) => (
                    <div style={{ color: "white" }}>
                      <TableDataVolumes key={item.id} item={item} />{" "}
                    </div>
                  ))}
              </div>

              {/* Right Container DESKTOP */}
            <div
                className="p-4 rounded-md text-white font-medium xl:overflow-y-auto mr-4 xl:mb-0 mb-2 xl:block hidden"
                style={{ backgroundColor: "#292929", maxHeight: "100%" }}
              >
              <div className="flex items-center justify-between xl:mx-0">
                <span className="font-semibold">Total Income:</span> &nbsp;
                <div
                    style={{ backgroundColor: "rgba(12,213,111,0.26)", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
                    className="flex items-center rounded-sm p-1.2"
                >
                  ≅&nbsp;
                  {volumes &&
                  Object.keys(volumes).length > 0 &&
                  volumes.sumBtcVolume.toFixed(1)}{" "}
                  &nbsp;
                  <FontAwesomeIcon
                      color="white"
                      icon={faDollarSign}
                      className="customUsdIcon"
                  />
                </div>
              </div>
              <div style={{
                display:'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <CoinProfitPieChart />
              </div>
                <hr className="mt-2" />
                {volumes.calculatedVolumesPerCoin &&
                  volumes.calculatedVolumesPerCoin.length > 0 &&
                  volumes.calculatedVolumesPerCoin.map((item) => (
                    <TableDataVolumes key={item.id} item={item} />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
