import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
// import Switch from "react-switch";
import {
  //   faMoon,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import DropDown from "./DropDown";
import OutsideClickHandler from "react-outside-click-handler";

const TopBar = ({ handleToggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const user = useSelector((state) => state.userReducer.user);
  const apiRequests = useSelector((state) => state.userReducer.apiRequests);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {user && Object.keys(user).length > 0 && (
        <div>
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <div className="top-bar-container flex items-center justify-between">
              <div className="btn-toggle" onClick={handleToggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div
                className="text-md text-white rounded-md lg:flex flex-row items-center gap-4 hidden p-2 w-auto mr-2"
                style={{ background: "#292929" }}
              >
                <h1 className="font-semibold"> API Requests:</h1>
                <p className="py-1">
                  Used Requests:{" "}
                  <span style={{ color: "#24ae7a" }}>
                    {apiRequests.usedRequests}
                  </span>
                </p>
                <p className="py-1">
                  Remaining Requests:{" "}
                  <span style={{ color: "#24ae7a" }}>
                    {apiRequests.remainingRequests}
                  </span>
                </p>
                <p className="py-1">
                  Requests Limit:{" "}
                  <span style={{ color: "#24ae7a" }}>
                    {apiRequests.requestsLimit}
                  </span>{" "}
                  <span className="italic"> per hour</span>
                </p>
              </div>
              <div className="flex items-center justify-end gap-1">
                <h3 style={{ fontSize: "16px", color: "white" }}>
                  Welcome,{" "}
                  <span className="font-semibold" style={{ color: "#24ae7a" }}>
                    {user.response.partnerName}
                  </span>
                </h3>

                <DropDown
                  isOpen={isOpen}
                  toggleIsOpen={toggleIsOpen}
                  handleToggleSidebar={handleToggleSidebar}
                  userIcon = {user.response.partnerImage}
                />
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
    </>
  );
};

export default TopBar;
