import React, { useState } from "react";
import Main from "./Main";
import MySidebar from "./Sidebar";

function Layout() {
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = () => {
    setToggled((prev) => {
      return !prev;
    });
  };

  return (
    <div className="app mainBG">
      <MySidebar toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
        <Main handleToggleSidebar={handleToggleSidebar} />
    </div>
  );
}

export default Layout;
