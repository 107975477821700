import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-date-fns';

const TransactionsChart = () => {
  const [labels, setLabels] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [dailyProfits, setDailyProfits] = useState([]);

  const currentMonthData = useSelector(
      (state) => state.userReducer.transactionsChartData.currentMonth
  );

  useEffect(() => {
    if (currentMonthData && Object.keys(currentMonthData).length > 0) {
      const totalOrders = [];
      const completedOrders = [];
      const profits = [];
      const chartLabels = [];

      Object.keys(currentMonthData).forEach((key) => {
        const dayData = currentMonthData[key];
        if (typeof dayData === "object" && dayData && dayData.day) {
          const dateLabel = new Date(dayData.year, dayData.month - 1, dayData.day); // Create a proper date object
          chartLabels.push(dateLabel);
          totalOrders.push(dayData.sumOrders || 0);
          completedOrders.push(dayData.completedOrders || 0);
          profits.push(dayData.eurProfit || 0);
        }
      });

      setTotalTransactions(totalOrders);
      setCompletedTransactions(completedOrders);
      setDailyProfits(profits);
      setLabels(chartLabels);
    }
  }, [currentMonthData]);

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Swaps",
        data: totalTransactions,
        backgroundColor: "#1d1d1d",
        borderColor: "#1d1d1d",
        borderWidth: 1,
        barThickness: 24,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
      {
        type: "bar",
        label: "Complete Swaps",
        data: completedTransactions,
        yAxisID: "y1",
        backgroundColor: "#0caa71",
        borderColor: "#0caa71",
        borderWidth: 1,
        fill: true,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
      {
        type: "line",
        label: "$ Profit",
        data: dailyProfits,
        yAxisID: "y2",
        borderColor: "#181818",
        backgroundColor: "transparent",
        borderWidth: 1.5,
        pointStyle: "triangle",
        pointRadius: 5,
        pointBackgroundColor: "#ffeff6",
        pointBorderColor: "#181818",
        tension: 0.4,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: 'dd/MM',
          },
          tooltipFormat: "dd/MM/yyyy",
        },
        title: {
          display: false,
          text: "Date",
          color: "#d5d5d5",
          font: {
            size: 12,
            family: "Arial",
          },
        },
        ticks: {
          color: "#d5d5d5",
        },
      },
      y: {
        beginAtZero: true,
        position: "left",
        title: {
          display: false,
          text: "Count",
          color: "#1d1d1d",
          font: {
            size: 12,
            family: "Arial",
          },
        },
        ticks: {
          color: "#d5d5d5",
          stepSize: 15,
        },
      },
      y1: {
        beginAtZero: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false,
          text: "Count",
          color: "#1d1d1d",
          font: {
            size: 12,
            family: "Arial",
          },
        },
        ticks: {
          color: "#d5d5d5",
          stepSize: 15,
        },
      },
      y2: {
        beginAtZero: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false,
          text: "Euro Profit",
          color: "#d5d5d5",
          font: {
            size: 12,
            family: "Verdana, Arial",
          },
        },
        ticks: {
          color: "#d5d5d5",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#d5d5d5",
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.formattedValue} ${tooltipItem.dataset.label} on ${tooltipItem.label}`;
          },
        },
        backgroundColor: "#1d1d1d",
        titleFont: {
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          size: 12,
        },
        footerFont: {
          size: 12,
        },
        footerColor: "#ffffff",
        borderColor: "#ffffff",
        borderWidth: 1,
        cornerRadius: 5,
        displayColors: false,
      },
    },
  };

  return (
      <div className="w-full relative">
        {data && options && <Chart type="bar" data={data} options={options} />}
      </div>
  );
};

export default TransactionsChart;
