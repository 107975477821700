import React from "react";
import { Route,  Routes, Navigate } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import TopBar from "./Topbar";
import Dashboard from "../../pages/Dashboard";
import Transactions from "../../pages/Transactions";
import Balances from "../../pages/Balances";
import ApiReference from "../../pages/ApiReference";
import ChangePassword from "../../pages/ChangePassword";
import EditWallet from "../../pages/EditWallet";
import FeeManagement from "../../pages/feeManagement";
import WhitelistedIps from "../../pages/WhitelistedIps";
import AvailableCoinsPairs from "../../pages/AvailableCoinsPairs";

const Main = ({ handleToggleSidebar }) => {
  const sized = useWindowSize();

  return (
      <div className="main" style={sized.width > 767 ? { padding: "13px 55px 0px 37px" } : null}>
      <TopBar handleToggleSidebar={handleToggleSidebar} />
      <Routes>
        <Route  path="/overview" element={<Dashboard/>} />
        <Route  path="/transactions" element={<Transactions/>} />
        <Route  path="/balances" element={<Balances/>} />
        <Route  path="/api-reference" element={<ApiReference/>} />
        <Route  path="/change-password" element={<ChangePassword/>} />
        <Route  path="/edit-wallet" element={<EditWallet/>} />
        <Route  path="/available-coins-pairs" element={<AvailableCoinsPairs/>} />
        <Route  path="/fee-management" element={<FeeManagement/>} />
        <Route  path="/whitelisted-ips" element={<WhitelistedIps/>} />

           {/* Redirect root "/" to "/overview" */}
           <Route path="/" element={<Navigate to="/overview" />} />

            {/* Catch-all route for undefined paths */}
<           Route path="*" element={<Navigate to="/overview" />} />
      </Routes>
    </div>
  );
};

export default Main;
