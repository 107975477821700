import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import apiIcon from "../images/api-icon.png";
import DataLoader from "../components/globalView/DataLoader";
import {
  getWhitelistedIps,
  addWhitelistedIp,
  deleteWhitelistedIp,
  handleLoadState
} from "../store/actions";

const isValidIP = (ip) => {
  const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?\d{1,2})$/;
  return ipRegex.test(ip);
};

const WhitelistedIps = () => {
  const dispatch = useDispatch();
  const [newIp, setNewIp] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddIpDiv, setShowAddIpDiv] = useState(false);
  const [isAddingIp, setIsAddingIp] = useState(false);
  const [localIps, setLocalIps] = useState([]);

  const isLoading = useSelector((state) => state.userReducer.loading);
  const whitelistedIps = useSelector((state) => state.userReducer.whitelistedIps || []);

  useEffect(() => {
    dispatch(handleLoadState(true));
    dispatch(getWhitelistedIps()).finally(() => dispatch(handleLoadState(false)));
  }, [dispatch]);

  useEffect(() => {
    setLocalIps(whitelistedIps);
  }, [whitelistedIps]);


  const addIp = () => {
    if (isValidIP(newIp)) {
      if (localIps.includes(newIp)) {
        setError('This IP is already in the list.');
      } else {
        setIsAddingIp(true);
        // Optimistic update
        setLocalIps(prevIps => [...prevIps, newIp]);
        dispatch(addWhitelistedIp(newIp))
          .then(() => {
            setNewIp('');
            setSuccessMessage('IP added successfully');
            setTimeout(() => setSuccessMessage(''), 2000);
          })
          .catch((err) => {
            // Revert optimistic update
            setLocalIps(prevIps => prevIps.filter(ip => ip !== newIp));
            setError('Failed to add IP. Please try again.');
          })
          .finally(() => {
            setIsAddingIp(false);
            setShowAddIpDiv(false);
          });
      }
    } else {
      setError('Please enter a valid IP address.');
    }
  };

  const deleteIp = (ipToDelete) => {
    // Optimistic update
    setLocalIps(prevIps => prevIps.filter(ip => ip !== ipToDelete));
    dispatch(deleteWhitelistedIp(ipToDelete))
      .then(() => {
        setSuccessMessage('IP deleted successfully');
        setTimeout(() => setSuccessMessage(''), 2000);
      })
      .catch((err) => {
        // Revert optimistic update
        setLocalIps(prevIps => [...prevIps, ipToDelete]);
        setError('Failed to delete IP. Please try again.');
        setTimeout(() => setError(''), 2000);
      });
  };

  if (isLoading) {
    return <DataLoader />;
  }

  return (
    <>
      <Helmet>
        <title>IP Whitelist Management | Instaswap Partners</title>
      </Helmet>
      <div className="px-0 main-area extraMn">
        <div className="flex md:flex-row flex-col justify-between items-center px-2">
          <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
            <h1 className="text-4xl text-white w-full text-center">Whitelisted IPs</h1>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start h-2/4 mt-20">
          {!showAddIpDiv && (
            <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
              <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                <img style={{ width: '140px', marginTop:'30px',marginBottom:'20px' }} src={apiIcon} alt="Logo" />
                {localIps.length > 0 ? (
                  <div>
                    <h2 className="text-gray-200">Whitelisted IP Addresses:</h2>
                    <ul className="mt-5 mb-5">
                      {localIps.map((ip) => (
                        <li key={ip} className="flex justify-between items-center text-white p-2 rounded-md">
                          <span>{ip}</span>
                          <button
                            onClick={() => deleteIp(ip)}
                            className="text-red-500 hover:text-red-700 focus:outline-none"
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-gray-200">No IPs whitelisted yet.</p>
                )}
              </div>
              <button
                className="w-full p-2 text-gray-200 rounded-md mt-6"
                style={{ background: "#23ac79" }}
                onClick={() => {
                  setShowAddIpDiv(true);
                  setError('');
                }}
              >
                Add New IP
              </button>
            </div>
          )}

          {showAddIpDiv && (
            <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
              <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                <img style={{ width: '140px', marginTop:'30px',marginBottom:'20px'}} src={apiIcon} alt="Logo" />
                <p className="text-gray-200 mb-6 text-center">
                  Please enter a valid IP address to whitelist. Only requests from whitelisted IPs will be accepted.
                </p>
                <div className="flex items-center justify-center gap-4 w-full">
                  <p className="text-gray-200 flex items-center">IP Address:</p>
                  <input
                    className="form-input"
                    type="text"
                    value={newIp}
                    onChange={(e) => setNewIp(e.target.value)}
                    placeholder="Enter IP address"
                  />
                </div>
                <div className="flex w-full gap-4">
                  <button
                    className="w-full p-2 text-gray-200 rounded-md mt-6"
                    style={{ background: "#23ac79" }}
                    onClick={addIp}
                    disabled={isAddingIp}
                  >
                    {isAddingIp ? (
                      <FontAwesomeIcon color="white" icon={faSpinner} spin />
                    ) : (
                      <span>Add IP</span>
                    )}
                  </button>
                  <button
                    className="w-full p-2 text-gray-200 rounded-md mt-6"
                    style={{ background: "#F0544E" }}
                    onClick={() => {
                      setShowAddIpDiv(false);
                      setError('');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          
          {(error || successMessage) && (
            <div className="xl:w-4/12 w-10/12 text-center p-2 mt-2 rounded">
              <div className={`${error ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'} p-2 rounded`}>
                {error || successMessage}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WhitelistedIps;