import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions, apiRequestLimits } from "../store/actions/";
import DataLoader from "../components/globalView/DataLoader";
import useWindowSize from "../utils/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import TableDataTransactions from "../components/tables/TableDataTransactions";
import ReactPaginate from "react-paginate";
import useDebounce from "../utils/UseDebounce";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import ChangeClientEmail from "../components/globalView/ChangeClientEmail";

const Transactions = () => {
  const dispatch = useDispatch();
  const sized = useWindowSize();

  const [currentPage] = useState(1);
  const [tableOpen, setTableOpen] = useState("");
  const [transaction, setTransaction] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  const transactions = useSelector((state) => state.userReducer.transactions);
  const isLoading = useSelector((state) => state.userReducer.loading);
  const customLoading = useSelector((state) => state.userReducer.customLoader);
  const pageSize = useSelector((state) => state.userReducer.transactions.pages);
  const transactionsArray =
    transactions &&
    Object.keys(transactions).length > 0 &&
    transactions.transactions;

  useEffect(() => {
    dispatch(getTransactions(null, 8));
    dispatch(apiRequestLimits());
  }, [dispatch]);

  const currentDiv = (id) => {
    setTableOpen(id);
  };

  const getTransDebounced = useDebounce((number) => {
    dispatch(getTransactions(number, 8));
  }, 200);

  function handlePageClick({ selected: selectedPage }) {
    getTransDebounced(selectedPage + 1);
  }

  const handlePrevClick = () => {
    getTransDebounced(currentPage - 1);
  };

  const handleNextClick = () => {
    getTransDebounced(currentPage + 1);
  };

  const responsive = () => {
    if (sized.width < 769) {
      return {
        width: "90%",
        marginLeft: "-7",
      };
    } else {
      return {
        width: "526px",
        marginLeft: "116",
      };
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: responsive().width,
      marginLeft: responsive().marginLeft,
      padding: "12px",
      background: "rgba(41,41,41,1)",
      border: "1px solid rgba(88, 88, 88, 1)",
      borderRadius: "10px",
      zIndex: 2000,
    },
  };

  const openModal = (value, item) => {
    setIsOpen(value);
    setTransaction(item);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const btnPrev = (
    <button
      style={
        pageSize === 1
          ? { display: "none", outline: "none" }
          : { display: "block", outline: "none" }
      }
      className="btn-pagination text-white"
      disabled={currentPage && currentPage <= 1}
      onClick={handlePrevClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />{" "}
    </button>
  );

  const btnNext = (
    <button
      style={
        pageSize === 1
          ? { display: "none", outline: "none" }
          : { display: "block", outline: "none" }
      }
      className="btn-pagination text-white"
      disabled={currentPage && currentPage === pageSize}
      onClick={handleNextClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />{" "}
    </button>
  );

  return (
    <>
      <Helmet>
        {" "}
        <title>Transactions | Instaswap Partners</title>{" "}
      </Helmet>
      {isLoading ? (
        <DataLoader />
      ) : (
        <CSSTransition
          timeout={{
            enter: 8500,
            exit: 500,
          }}
          classNames="item"
          key="transactions"
        >
          <div className="px-0 main-area">
            <>
                <div className="flex md:block justify-between items-center px-2">
                  <div>
                    <h1 className="text-4xl text-white">Transactions </h1>
                    <h3
                      className="text-gray-100 text-xl mt-2 mb-4"
                      style={{ color: "#b8c2cc", zIndex: "9" }}
                    >
                      Your recent transactions
                    </h3>
                  </div>
                </div>
              <div className="mx-auto px-2 overflow-x-auto">
                {transactions && Object.keys(transactions).length > 0 ? (
                  <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table
                      className="w-full leading-normal relative overflow-x-auto"
                      style={
                        customLoading === true ? { height: "323px" } : null
                      }
                    >
                      {sized.width > 1024 && !customLoading ? (
                        <thead>
                          <tr
                            style={{
                              background: "#292929",
                              borderBottom: "2px solid #1D1D1D",
                            }}
                          >
                            <th className="md:px-2 px-5 py-3 text-left text-xs text-gray-500  tracking-wider">
                              ID
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Transaction Name
                            </th>

                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Amount
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Profit
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Status
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Tx ID
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Client Email
                            </th>
                            <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                              Date
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr
                            style={{
                              background: "#292929",
                              borderBottom: "2px solid #1D1D1D",
                            }}
                          >
                            <th className="md:px-2 px-5 py-3 text-left text-xs text-gray-500 tracking-wider">
                              Transaction Details
                            </th>
                          </tr>
                        </thead>
                      )}
                      {customLoading ? (
                        <div className="my-16">
                          <DataLoader className="h-full" />
                        </div>
                      ) : (
                        <>
                          {" "}
                          {transactionsArray &&
                            transactionsArray.length > 0 &&
                            transactionsArray.map((item) => (
                              <TableDataTransactions
                                key={item.id}
                                item={item}
                                tableOpen={tableOpen}
                                currentDiv={currentDiv}
                                openModal={(open) => openModal(open, item)}
                              />
                            ))}{" "}
                        </>
                      )}
                    </table>

                    {transactions &&
                      Object.keys(transactions).length > 0 &&
                      pageSize &&
                      pageSize > 1 && (
                        <div
                          className="px-5 py-5 border-t flex items-center text-white pagination"
                          style={{ backgound: "#292929" }}
                        >
                          <ReactPaginate
                            previousLabel={btnPrev}
                            nextLabel={btnNext}
                            breakLabel={"..."}
                            disableInitialCallback={false}
                            breakClassName={"break-me"}
                            pageCount={pageSize}
                            hrefBuilder={() => currentPage + 1}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageClick}
                            forcePage={currentPage - 1}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="inline-block min-w-full text-white text-center p-5 shadow rounded-lg overflow-hidden box empty-table">
                    <h5>No Transactions yet</h5>
                  </div>
                )}
              </div>

              <Modal
                isOpen={modalIsOpen}
                closeTimeoutMS={500}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button
                  onClick={closeModal}
                  className="w-full text-right"
                  style={{ fontSize: "23px", paddingRight: "10px" }}
                >
                  <FontAwesomeIcon color="white" icon={faTimes} />{" "}
                </button>
                <ChangeClientEmail item={transaction} setIsOpen={setIsOpen} />
              </Modal>
            </>
          </div>
        </CSSTransition>
      )}
    </>
  );
};

export default Transactions;
